import React from "react"

import { ProjectCard } from 'src/components/project/card'
import { ProjectInt } from 'src/components/interfaces/project'

export interface ProjectGridProps {
  data: {
    title?: string
    shortDescription?: string
    projects?: [ProjectInt]
  }
}

export const ProjectGrid = ({ data }: ProjectGridProps) => {
  const { title, projects } = data
  return (
    <div className="container--xxl outer mxa pt2 pb2 al p1 p x">
      <h3 className='mt0 p0 mb2'>{title}</h3>
      <div className='row'>
        {projects && projects.map((singleProject) => (
          <ProjectCard key={singleProject._id} {...singleProject} />
        ))}
        <div className='x c30' />
      </div>
    </div>
  )
}