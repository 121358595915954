import React from 'react'

import { StandardText, StandardTextProps } from 'src/components/global/standardText'
import { ProductGrid, ProductGridProps } from 'src/components/global/productGrid'
import { ProjectGrid, ProjectGridProps } from 'src/components/global/projectGrid'
import { NestedPages, NestedPagesProps } from 'src/components/global/nestedPages'


export const Modules = ({ reactModule, type }: { type: string, reactModule: any }) => {
  switch(type) {
    case 'standardText':
      return <StandardText data={reactModule as StandardTextProps["data"]} />
      case 'productGrid':
        return <ProductGrid data={reactModule as ProductGridProps["data"]} />
      case 'projectGrid':
        return <ProjectGrid data={reactModule as ProjectGridProps["data"]} />
      case 'nestedPages':
        return <NestedPages data={reactModule as NestedPagesProps["data"]} />
    default:
      return (<span>{type}</span>)
  }
}