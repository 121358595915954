import React, { useState, useEffect } from 'react'

import { PageLink } from 'src/components/link'
import { Image } from 'src/components/image'
import { ProductInt } from 'src/components/interfaces/product'
import { ProductForm } from './form'

import { client, useAddItemToCart } from 'src/context/siteContext'

import styled from 'styled-components'


const ProductItem = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
grid-template-rows: 1fr;
margin: 5px 0 30px 0;
padding-top:20px;
border-top: 1px solid #dcdcdc;
` 

export const ProductCard = ({
  _id,
  content: {
    main,
    shopify
  }
}: ProductInt ) => {
  return (
    <ProductItem key={_id}>
      
          <div><PageLink className='block' to={`/products/${main.slug.current}`}>{main.title}</PageLink></div>
          <div>Description to go here</div>
          <div>
        <ProductForm {...shopify} showQuantity={false} waitlist={false} addText={'Quick Add'} />
        <span>${shopify.defaultPrice}</span>
      </div>
          
          <div><PageLink className='block' to={`/products/${main.slug.current}`}>{main.mainImage && (<Image className='db x' imageId={main.mainImage.asset._ref} alt={main.title} />)}</PageLink></div>


    </ProductItem>
  )
}
