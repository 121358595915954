import React, { useState, useEffect } from 'react'

import { PageLink } from 'src/components/link'
import { Image } from 'src/components/image'
import { ProjectInt } from 'src/components/interfaces/project'

import { client, useAddItemToCart } from 'src/context/siteContext'

export const ProjectCard = ({
  _id,
  content: {
    main
  }
}: ProjectInt ) => {
  return (
    <div className='col c12 mb1 c6--600 c6--800 project__card' key={_id}>
      <PageLink className='block' to={`/projects/${main.slug.current}`}>
        {main.mainImage && (<Image className='db x' imageId={main.mainImage.asset._ref} alt={main.title} />)}
        <div className='df jcb mt1 grid__project-title aic'>
          <span>{main.title}</span>
        </div>
      </PageLink>
    </div>
  )
}
