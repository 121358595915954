import React from "react"

import { ProductCard } from 'src/components/product/card'
import { ProductInt } from 'src/components/interfaces/product'

import styled from 'styled-components'

const Padding = '20px';
const borderStyle = '1px solid #dadada';

const Main = styled.div`
  display: grid;
  border-top:${borderStyle};
  padding:${Padding};
 ` 

const Title = styled.h2`
font-family: 'ABC Monument Grotesk';
font-weight:300;
font-size:9em;
display: grid;
color:#929292;
grid-column: 1 / -1;
margin:0 0 20px 0;
line-height: 1em;
` 


export interface ProductGridProps {
  data: {
    title?: string
    shortDescription?: string
    products?: [ProductInt]
  }
}

export const ProductGrid = ({ data }: ProductGridProps) => {
  const { title, products } = data
  return (
    <Main>
      <Title>{title}</Title>
        {products && products.map((singleProduct) => (
          <ProductCard key={singleProduct._id} {...singleProduct} />
        ))}
    </Main>
  )
}